import { User } from "@prisma/client";
import { SUBSCRIPTION_STATUS } from "./subscription";

const limits: Record<
  string,
  {
    goals: number;
    todos: number;
    results: number;
    tags: number;
    trackedTime: number;
    media: number;
    itemList: number;
    notes: number;
    feedbacks: number;
  }
> = {
  free: {
    goals: 10,
    todos: 50,
    results: 625,
    tags: 10,
    trackedTime: 1000000,
    media: 5,
    itemList: 10,
    notes: 5000,
    feedbacks: 1,
  },
  premium: {
    goals: 50,
    todos: 100,
    results: 1250,
    tags: 20,
    trackedTime: 2000000,
    media: 10,
    itemList: 20,
    notes: 10000,
    feedbacks: 3,
  },
};
export const getLimits = (user: User) => {
  const key =
    user.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ? "premium" : "free";
  return limits[key];
};
export default limits;
