import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    common: {
      BackToHome: "To main page",
      Main: "Todos",
      Goals: "Goals",
      GoalsTooltip: "Big life tasks",
      GoalsDescription:
        "Add what you are striving for, and a todo list and visible progress will help you achieve it.",
      Goal: "Goal",
      GoalsTabActive: "Active",
      GoalsTabFinished: "Finished",
      GoalsTabAll: "ALL",
      GoalsListError: "Failed to load goals",
      GoalsListEmpty: "This goal list is empty",
      GoalFormName: "Name",
      GoalFormCreate: "Create goal",
      GoalFormCreating: "Creating...",
      GoalFormMessageSuccess: "Goal created!🎉",
      GoalFormMessageUpdated: "Goal updated!",
      GoalMetric: "Goal metric",
      GoalMetricTooltip: "Quantifying the goal",
      GoalMetricDescription:
        "How much and what needs to be done to achieve the goal?",
      GoalMetricEmpty: "Metric is not set",
      GoalMetricName: "Metric name",
      GoalMetricTarget: "Target value",
      GoalMetricCurrent: "Current value",
      Achievements: "Achievements",
      AchievementsTooltip: "Virtual rewards",
      AchievementsDescription:
        "Complete todos and goals, add results, be active - rewards are waiting for you!",
      AchievementsTabActive: "ACTIVE",
      AchievementsTabReceived: "MY COLLECTION",
      AchievementsTabAll: "ALL",
      AchievementsListError: "Failed to load achievements",
      AchievementsListEmpty: "This achievement list is empty",
      "Achievement unlocked": "Achievement unlocked",
      Profile: "Profile",
      Settings: "Settings",
      Productivity: "Productivity",
      ProductivityChartTitle: "Productivity of last month",
      ProductivityChartTooltip: "Relative value",
      ProductivityChartDescription:
        "Depends on your activity - completing todos and goals, adding results.",
      ChartEmptyTitle: "The data is given as an example.",
      ChartEmptyDescription:
        "Your chart will be displayed when you complete todo, goal, or add result.",
      GoalsChartTitle: "Goals of last month",
      GoalsChartTooltip: "Goals which are given the most attention",
      GoalsChartDescription:
        "Depends on your activity - completing todos and goals, adding results.",
      GoalPriority: "Priority",
      TodoListEmpty: "No todos added yet",
      "Goal finished": "Goal finished",
      "Todo finished": "Done",
      "Goals achieved": "Goals achieved",
      "Todos finished": "Todos finished",
      "Results added": "Results added",
      "Achievements received": "Achievements received",
      TMR: "TMR",
      "New result for": "New result for",
      "Share result details":
        "Share the result of what has been done for this goal",
      importance: "importance",
      complexity: "complexity",
      interest: "interest",
      "Adding result": "Adding result...",
      "Result added": "Result added!🎉",
      "Adding todo": "Adding todo...",
      "Todo added": "Todo added!🎉",
      GoalProgress: "Progress",
      GoalProgressTooltip: "Current goal progress",
      GoalProgressDescription:
        "Have a question or an idea? Write to support@forgeself.com",
      GoalPersonalReward: "Personal reward",
      GoalPersonalRewardTooltip: "Encouragement from myself",
      GoalPersonalRewardDescription:
        "What will you reward yourself with when you complete this goal?",
      GoalTodos: "Todos",
      GoalTodosTooltip: "Divide the goal into a todo list",
      GoalTodosDescription:
        "Within each, you can create a list of nested todos.",
      Todo: "Todo",
      TodoProgress: "Progress",
      TodoProgressTooltip: "Current todo progress",
      TodoProgressDescription:
        "Did you find a bug or problem? Write to support@forgeself.com",
      TodoPersonalReward: "Personal reward",
      TodoPersonalRewardTooltip: "Encouragement from myself",
      TodoPersonalRewardDescription:
        "What will you reward yourself with when you complete this todo?",
      TodoFormName: "Name",
      TodoFormAdding: "Adding...",
      TodoFormAdd: "Add",
      TodoTodos: "Todos",
      TodoTodosTooltip: "Divide todo into smaller todos",
      TodoTodosDescription:
        "Within each, you can create a list of nested todos.",
      TodoDeleteConfirm: "Delete this todo permanently?",
      TodoComplexity: "Complexity",
      TodoComplexityLow: "Easy",
      TodoComplexityNormal: "Regular",
      TodoComplexityHigh: "Complex",
      TodoFinishConfirm: "Finish this todo? (The action is irreversible)",
      GoalDeleteConfirm: "Delete this goal permanently?",
      GoalFinishConfirm: "Complete the goal? (The action is irreversible)",
      PersonalRewardAdded: "Personal reward added!🎉",
      PersonalRewardPlaceholder: "Specify a personal reward",
      PersonalRewardEmpty: "Reward was not added before completion",
      PersonalRewardReceiveConfirm: "Are you sure you got a reward?",
      PersonalRewardReceive: "Receive",
      PersonalRewardReceived: "Received",
      PersonalRewardAvailable: "Reward available",
      Results: "Results",
      ResultsTooltip: "Completed todos that were not on the list",
      ResultsDescription:
        "Mark even small results and do not forget to rate for statistics.",
      ResultsListEmpty: "Results not added yet",
      ResultDeleteConfirm: "Delete this result permanently?",
      Nickname: "Nickname",
      Rank: "Rank",
      "TMR helper info":
        "TMR — things making ratio, number that abstractly shows your activity index in current season.",
      "Finished goals": "Finished goals",
      HomePageTitle: "Reach your goals faster and easier!",
      "Your email": "Your email",
      "Set email": "Set email",
      "Verification message":
        "Check your inbox! An email has been sent to you with verification code.",
      "Verification code": "Verification code",
      "Why set email":
        "Setting the email prevents you from losing all your account data when your device accidently logs out your account.",
      "Log out": "Log out",
      Login: "Login",
      AuthPageTitle: "Login",
      "Email address": "Email address",
      "Login code": "Login code",
      "Login with Email": "Login with Email",
      Send: "Send",
      "Login message":
        "Check your inbox! An email has been sent to you with a magic link and login code.",
      "Rules and privacy": "Rules and privacy",
      "Terms of Service": "Terms of Service",
      "Privacy Policy": "Privacy Policy",
      "Landing heading": "Planner for tasks and goals",
      "Landing title": "Reach your goals faster and easier!",
      "Landing subtitle":
        "Maintaining motivation, daily planner, achievements, statistics, time tracker, focusing",
      "Landing Reason 1": "Обучающее задание для быстрого старта",
      "Landing Reason 2": "Синхронизация между устройствами",
      "Landing Reason 3": "Улучшаемые достижения",
      "Landing Reason 4": "Награды от себя за выполнение дел и целей",
      "Landing Reason 5": "Эффективные списки дел",
      "Landing Reason 6": "Персональный календарь активности",
      "Landing Reason 7": "Вложенные дела без ограничений по уровню",
      "Landing Reason 8": "Графики статистики продуктивности",
      "Landing Reason 9": "Повторяющиеся дела с настройкой расписания",
      "Log in": "Log in",
      "Demo goal1 name": "Help 10 people achieve their life goals",
      "Demo goal2 name": "Create my own business",
      "Loading...": "Loading...",
      "Goal not found": "Goal not found",
      "Add goal": "Add goal",
      "Add todo": "Add todo",
      Priority: "Priority",
      Low: "Low",
      Normal: "Normal",
      High: "High",
      "Not found helper info": "Try to go back and find what you need.",
      "Finish this goal": "Finish this goal",
      "Goal finish info": `Congratulations! Note, that goal can't be activated again,
      the goal and all related todos will be locked from editing.`,
      "Finish goal": "Finish goal",
      Yes: "Yes",
      Add: "Add",
      Delete: "Delete",
      Finish: "Finish",
      Cancel: "Cancel",
      Save: "Save",
      Saving: "Saving",
      "Delete this goal": "Delete this goal",
      "Delete goal info": `Delete is permanent. Goal can not be restored. All related todos and
      reminders will be deleted.`,
      "DELETE GOAL": "DELETE GOAL",
      "Blank todo": "Blank todo",
      "Untitled goal": "Untitled goal",
      "Untitled todo": "Untitled todo",
      "Open todo page": "Open page",
      Finished: "Finished",
      Achieved: "Achieved",
      "Finish todo info": `Congratulations! Note, that todo can't be activated again,
      the todo will be set as done and locked from editing.`,
      "FINISH TODO": "FINISH TODO",
      "Todos list info": "This is todo list of this goal.",
      "This goal does not have todos yet.":
        "This goal does not have todos yet.",
      "Goals limit info": `Try to focus on top 5 main goals. Increasing active goals number
      can lead to diffuse efforts and not reaching any goal at all.`,
      "Reset password": "Reset password",
      "Password reset link sent to your email!":
        "Password reset link sent to your email!",
      "Provided incorrect email!": "Provided incorrect email!",
      "Can not receive reward!": "Can not receive reward!",
      SelectIcon: "Select icon",
      RemoveIcon: "Remove icon",
      NextReward: "Next reward",
      rank: "rank",
      ach_goals_name: "Through hardship to the stars",
      ach_goals_content: "Finish goals to get and improve this achievement.",
      ach_goals_item: "goal",
      ach_goals_items: "goals",
      ach_goals_action: "achieved",
      ach_todos_name: "Practice makes perfect",
      ach_todos_content: "Finish todos to get and improve this achievement.",
      ach_todos_item: "todo",
      ach_todos_items: "todos",
      ach_todos_action: "finished",
      ach_results_name: "Fish from the pond",
      ach_results_content: "Add results to get and improve this achievement.",
      ach_results_item: "result",
      ach_results_items: "results",
      ach_results_action: "added",
      ach_rise_and_shine_name: "Rise and shine",
      ach_rise_and_shine_content:
        "Complete the first quest - tutorial to get this achievement.",
      ach_tracked_time_name: "Business before pleasure",
      ach_tracked_time_content:
        "Track time to get and improve this achievement.",
      ach_tracked_time_item: "hour",
      ach_tracked_time_items: "hours",
      ach_tracked_time_action: "tracked",
      ach_premium_name: "Money can't buy happiness",
      ach_premium_content:
        "Pay ForgeSelf Premium subscription to get this achievement.",
      ach_feel_at_home_name: "Feel at home",
      ach_feel_at_home_content:
        "Upload a profile photo to get this achievement.",
      ach_feedback_name: "Perfect is the enemy of good",
      ach_feedback_content: "Submit feedback to get this achievement.",
      ach_focusing_name: "Own master",
      ach_focusing_content:
        "Add focusing cards to get and improve this achievement.",
      "Access denied": "Access denied",
      "You must be signed in to view this page":
        "You must be signed in to view this page",
      "Page not found": "Page not found",
      "Page not found details":
        "Try to go back to the main page and search again.",
      CommonError: "Something went wrong 😥 Try again",
      OfflineMessage: "No internet connection.",
      AccountSettings: "Account settings",
      AccountDeleteInfo:
        "You can completely delete your account with all its data. A deleted account cannot be recovered, the data is permanently deleted.",
      AccountDelete: "Delete account",
      AccountDeleteConfirm:
        "Are you sure you want to delete your account? To delete, enter the word DELETE and click the Delete account button.",
      AccountDeleteConfirmFinal: "Think again, the deletion is irreversible.",
      AccountDeleted: "Your account has been deleted as requested.",
      Support: "Support",
      Emoji: "Emoji",
      AuthTitle: "Rise and shine!",
      AuthSubtitle: "Enter your email\nto start on your goals journey",
      TryingAuthSubtitle: "Pass captcha\nto start on your goals journey",
      When: "When",
      Today: "Today",
      Tomorrow: "Tomorrow",
      "Next week": "Next week",
      "Pick date": "Pick date",
      Pending: "Pending",
      "Chill, no todos for this list": "Chill, no todos for this list",
      "No pending todos": "No pending todos",
      Clear: "Clear",
      Repeat: "Repeat",
      Daily: "Daily",
      "Working days": "Working days",
      Weekly: "Weekly",
      Monthly: "Monthly",
      Yearly: "Yearly",
      Customize: "Customize",
      MO: "MO",
      TU: "TU",
      WE: "WE",
      TH: "TH",
      FR: "FR",
      SA: "SA",
      SU: "SU",
      ImageDeleteConfirm: "Delete this image?",
      NicknameLengthError: "Nickname length must be between 2 and 32 symbols",
      "You will get": "You will get",
      experience: "Experience",
      experiencePlural: "experience",
      DeepLinksTitle: "Check app settings",
      DeepLinksButton: "App links",
      DeepLinksInfo: `To make forgeself.com links open in the Android application,
      you need to enable the "Open supported links" setting as "Open", then click "Add link" > "forgeself.com".
      This will allow one click auth with the link from email.`,
      DeepLinksOpenAppSettings: "Open app settings?",
      Week: "Week",
      Month: "Month",
      Period: "Period",
      Tags: "Tags",
      "Select or add tags": "Select or add tags",
      Comment: "Comment",
      "Adding tracked time": "Adding tracked time",
      "Tracked time added": "Tracked time added",
      TrackTimeChartEmptyTitle: "No data for the selected period",
      TrackTimeChartEmptyDescription: "Mark the time to see stats",
      Confirm: "Confirm",
      TrackTimeDeleteConfirm: "Delete this tracked time permanently?",
      TrackedTimeEdit: "Edit tracked time",
      Blue: "Blue",
      Pink: "Pink",
      Green: "Green",
      Orange: "Orange",
      Purple: "Purple",
      Yellow: "Yellow",
      Gray: "Gray",
      Red: "Red",
      Brown: "Brown",
      Slate: "Slate",
      "Time tracker": "Time tracker",
      "Support ForgeSelf": "Support ForgeSelf",
      TagDeleteConfirm: "Delete this tag permanently?",
      Morning: "Morning",
      Afternoon: "Afternoon",
      Evening: "Evening",
      "Move to": "Move to",
      Status: "Status",
      "In progress": "In progress",
      Base: "Base",
      BaseTariffDescription: "Base tariff does not require subscription.",
      Premium: "Premium",
      "Your subscription is active.": "Your subscription is active.",
      "Next payment date:": "Next payment date:",
      "Manage subscription": "Manage subscription",
      "Support project message":
        "Like ForgeSelf? Wanna make it better? Boost it right now:",
      PremiumFeatureLimitsTitle: "Extended limits",
      PremiumFeatureLimitsSubitle:
        "Up to 50 active goals, up to 100 todo in lists.",
      PremiumFeatureFocusingTitle: "Focusing",
      PremiumFeatureFocusingSubitle:
        "Set priorities and focus on what matters most.",
      PremiumFeatureAutotimetrackingTitle: "Automatic time tracking",
      PremiumFeatureAutotimetrackingSubitle:
        "Time spent on todos is automatically tracked by goal tags.",
      PremiumFeatureDetailedStatsTitle: "Detailed statistics",
      PremiumFeatureDetailedStatsSubitle: "View statistics for any period.",
      PremiumFeaturePowerfulProgressTitle: "Powerful progress slider",
      PremiumFeaturePowerfulProgressSubitle:
        "Track your todos progress with a handy chart.",
      "Your tariff:": "Your tariff:",
      EARLYBIRD: "Early bird",
      "Next payment:": "Next payment:",
      "Cancel subscription": "Cancel subscription",
      " and unlink the card": " and unlink the card",
      LeaveSubscriptionConfirm:
        "You can opt out of automatic renewal. In this case, ForgeSelf no longer be able to bill you, and the subscription will end at the end of the current period.",
      Unsubscribe: "Unsubscribe",
      Focusing: "Focusing",
      FocusingPercentLabel: "You are focused on your priorities by",
      FocusingPercentPositive: "Excellent result! Continue in the same spirit!",
      FocusingPercentNegative:
        "You are out of focus. Take a look at your priorities.",
      FocusingChartEmptyTitle: "Not enough data",
      FocusingChartEmptySubtitle:
        "Choose your priorities and track time to build focusing chart",
      FocusingUnderfocusTitle: "Spend more time in these categories:",
      FocusingOverfocusTitle: "Spend less time in these categories:",
      "My priorities": "My priorities",
      FocusPriority5: "Maximum attention",
      FocusPriority4: "Important",
      FocusPriority3: "Don't forget",
      FocusPriority2: "By situation",
      FocusPriority1: "Not important",
      "Available with": "Available with",
      AutomaticGoalTagsTrackingMessage:
        "Automatically track time when completing todos without categories",
      progressHillLeft: "figuring things out",
      progressHillRight: "making it happen",
      Pricing: "Pricing",
      Bills: "Bills",
      Tariff: "Tariff",
      Date: "Date",
      Sum: "Sum",
      BILL_STATUS_SUCCESS: "Paid",
      BILL_STATUS_PENDING: "Pending",
      BILL_STATUS_CANCELED: "Canceled",
      SubscriptionEndsAt: "Subscription will end at",
      SubscriptionWebControls: "Subscription management",
      OpenInBrowser: "Open in browser",
      NotAvailableInApp: "Subscription management is not available in app yet",
      TimePickerStart: "Start",
      TimePickerFinish: "Finish",
      Progress: "Progress",
      "Due dates": "Due dates",
      "Goal set at": "Goal set at",
      "Due date": "Due date",
      "Start date": "Start date",
      "Time left": "Time left",
      "not specified": "not specified",
      "not specified / femine": "not specified",
      "not specified / neuter": "not specified",
      Overdue: "Overdue",
      Schedule: "Schedule",
      "Total days": "Total days",
      "Best streak": "Best streak",
      "Payment method: card *": "Payment method: card *",
      "web-version": "web-version",
      CreateNewGoalList: "Create new list",
      "Adding goal list": "Adding goal list",
      "Goal list added": "Goal list added",
      "Goal lists": "Goal lists",
      "All goals": "All goals",
      "Untitled goal list": "Untitled goal list",
      "Untitled todo list": "Untitled todo list",
      GoalListsHelper:
        "You can organize your goals into groups for easy work and focus.",
      GoalListDeleteConfirm:
        "Delete this goal list permanently? Goals inside it will not be affected.",
      CreateNewTodoList: "Create new list",
      TodoListsManagement: "Lists management",
      TodoListDeleteConfirm:
        "Delete this goal list permanently? Todos inside it will be deleted.",
      TodoListsModeToList: "Display as list",
      TodoListsModeToColumn: "Display as columns",
      TodoListsHideFinished: "Hide finished",
      TodoListsShowFinished: "Show finished",
      Search: "Search",
      SearchPlaceholder: "Search todos or goals",
      SearchResultEmpty: "Nothing found",
      EditorAddDescription: "Add description ...",
      EditorFinishEditing: "Finish editing",
      EditorSetLink: "Set link",
      FocusingCardContentPlaceholder: "Card text ...",
      Archive: "Archive",
      Focus: "Focus",
      FocusingSetArchived: "To archive",
      FocusingSetActive: "Activate",
      FocusingCardDeleteConfirm: "Delete this card permanently?",
      FocusingNoteFormMessageSuccess: "Focusing card added!🎉",
      NotesFOCUSING_PRIORITY: "Priorities",
      NotesFOCUSING_ENERGY: "Energy",
      NotesFOCUSING_ATTENTION: "Attention",
      NotesFOCUSING_VALUES: "Values",
      NotesFOCUSING_PRIORITYQuestion:
        "What plans and tasks are most important now?",
      NotesFOCUSING_ENERGYQuestion:
        "What activities charge me with creativity and productivity?",
      NotesFOCUSING_ATTENTIONQuestion:
        "What ideas, judgments and conclusions are worth keeping in mind?",
      NotesFOCUSING_VALUESQuestion:
        "What principles, beliefs and ideals define my life position?",
      "Continue without login": "Continue without login",
      FeedbackTitle: "How do you like this page?",
      FeedbackIsUseful: "Like / useful",
      FeedbackIsUseless: "Don't like it / useless",
      FeedbackIsConvenient: "Convenient / clear",
      FeedbackIsUnconvenient: "Not convenient / not clear",
      FeedbackPlaceholder: "Share a problem or comment",
      FeedbackFormCreate: "Submit",
      FeedbackFormCreating: "Submitting...",
      FeedbackFormMessageSuccess: "Feedback has been submitted!🎉",
      FeedbackThanks:
        "Feedback has been submitted. Thank you for your attention to the quality of the product!",
    },
    settings: {
      UI_CUSTOM_PROGRESS: "Show progress slider for every todo",
      AppTheme: "App theme",
      AppThemeLight: "Light",
      AppThemeDark: "Dark",
      AppThemeAuto: "Auto",
    },
    QUEST_RISE_AND_SHINE: {
      next: "Next",
      step1Title: "Add goal",
      step1Subtitle: "Set title and emoji",
      step1Action: "Create goal",
      step2Title: "Create a todo list",
      step2Subtitle: "Name the steps to achieve goals or set regular tasks",
      step3Title: "Add reward",
      step3Subtitle: "What will please you after the goal is completed?",
      step4Title: "Add result",
      step4Subtitle: "What has been already done for this goal?",
      step5Title: "Great start!",
      step5Subtitle:
        "The goal is set, the path is laid, there is motivation and even the first result!",
      finish: "Finish tutorial",
    },
    docs: {
      Learn: "Learn",
      "Why ForgeSelf?": "Why ForgeSelf?",
      "Use cases": "Use cases",
      "How to": "How to",
      Company: "Company",
      "Goals achieving problem": "Goals achieving problem",
      "Planning things problem": "Planning things problem",
      "Flexibility solves": "Flexibility solves",
      "Positive motivation": "Positive motivation",
      Personalization: "Personalization",
      "Time saving": "Time saving",
      "Goal tracker": "Goal tracker",
      "Daily planner": "Daily planner",
      "Focusing": "Focusing",
      "Time tracker": "Time tracker",
    },
  },
  ru: {
    common: {
      BackToHome: "На главную",
      Main: "Дела",
      Goals: "Цели",
      GoalsTooltip: "Большие жизненные задачи",
      GoalsDescription:
        "Добавьте то к чему стремитесь, а список дел и видимый прогресс помогут достичь.",
      Goal: "Цель",
      GoalsTabActive: "Активные",
      GoalsTabFinished: "Завершённые",
      GoalsTabAll: "ВСЕ",
      GoalsListError: "Не удалось загрузить цели",
      GoalsListEmpty: "Этот список целей пуст",
      GoalFormName: "Название",
      GoalFormCreate: "Создать цель",
      GoalFormCreating: "Создаем...",
      GoalFormMessageSuccess: "Цель добавлена!🎉",
      GoalFormMessageUpdated: "Цель обновлена!",
      GoalMetric: "Метрика цели",
      GoalMetricTooltip: "Количественное выражение цели",
      GoalMetricDescription:
        "Сколько и чего нужно сделать для достижения цели?",
      GoalMetricEmpty: "Метрика не задана",
      GoalMetricName: "Название метрики",
      GoalMetricTarget: "Целевое значение",
      GoalMetricCurrent: "Текущее значение",
      Achievements: "Достижения",
      AchievementsTooltip: "Виртуальные награды",
      AchievementsDescription:
        "Завершайте дела и цели, добавляйте результаты, проявляйте активность – вас ждут награды!",
      AchievementsTabActive: "АКТИВНЫЕ",
      AchievementsTabReceived: "МОЯ КОЛЛЕКЦИЯ",
      AchievementsTabAll: "ВСЕ",
      AchievementsListError: "Не удалось загрузить достижения",
      AchievementsListEmpty: "Этот список достижений пуст",
      "Achievement unlocked": "Получено достижение",
      Profile: "Профиль",
      Settings: "Настройки",
      Productivity: "Продуктивность",
      ProductivityChartTitle: "Продуктивность за месяц",
      ProductivityChartTooltip: "Относительная величина",
      ProductivityChartDescription:
        "Зависит от вашей активности – завершения дел и целей, добавления результатов.",
      ChartEmptyTitle: "Данные приведены для примера.",
      ChartEmptyDescription:
        "Ваш график отобразится когда вы завершите дело, цель или добавите результат.",
      GoalsChartTitle: "Цели за месяц",
      GoalsChartTooltip: "Каким целям уделили больше внимания",
      GoalsChartDescription:
        "Зависит от вашей активности – завершения дел и целей, добавления результатов.",
      GoalPriority: "Приоритет",
      TodoListEmpty: "Дела пока не добавлены",
      "Goal finished": "Цель достигнута",
      "Todo finished": "Сделано",
      "Goals achieved": "Целей достигнуто",
      "Todos finished": "Дел сделано",
      "Results added": "Результатов добавлено",
      "Achievements received": "Достижений получено",
      TMR: "TMR",
      "New result for": "Новый результат для",
      "Share result details":
        "Поделитесь результатом, что сделано для этой цели",
      importance: "важность",
      complexity: "сложность",
      interest: "интерес",
      "Adding result": "Добавляем результат...",
      "Result added": "Результат добавлен!🎉",
      "Adding todo": "Добавляем новое дело...",
      "Todo added": "Дело добавлено!🎉",
      GoalProgress: "Прогресс",
      GoalProgressTooltip: "Текущий прогресс дел цели",
      GoalProgressDescription:
        "Есть вопрос или идея? Пиши на support@forgeself.com",
      GoalPersonalReward: "Личная награда",
      GoalPersonalRewardTooltip: "Поощрение от себя",
      GoalPersonalRewardDescription: "Чем наградите себя по завершении цели?",
      GoalTodos: "Дела",
      GoalTodosTooltip: "Разделите цель на список дел",
      GoalTodosDescription:
        "Внутри каждого можно создать список вложенных дел.",
      Todo: "Дело",
      TodoProgress: "Прогресс",
      TodoProgressTooltip: "Текущий прогресс дела",
      TodoProgressDescription:
        "Нашел ошибку или неисправность? Пиши на support@forgeself.com",
      TodoPersonalReward: "Личная награда",
      TodoPersonalRewardTooltip: "Поощрение от себя",
      TodoPersonalRewardDescription: "Чем наградите себя по завершении дела?",
      TodoFormName: "Название",
      TodoFormAdding: "Добавляем...",
      TodoFormAdd: "Добавить дело",
      TodoTodos: "Дела",
      TodoTodosTooltip: "Разделите дело на дела поменьше",
      TodoTodosDescription:
        "Внутри каждого можно создать список вложенных дел.",
      TodoDeleteConfirm: "Удалить это дело безвозвратно?",
      TodoFinishConfirm: "Завершить дело? (Действие необратимо)",
      TodoComplexity: "Сложность",
      TodoComplexityLow: "Простое",
      TodoComplexityNormal: "Обычное",
      TodoComplexityHigh: "Сложное",
      GoalDeleteConfirm: "Удалить эту цель безвозвратно?",
      GoalFinishConfirm: "Завершить цель? (Действие необратимо)",
      PersonalRewardAdded: "Личная награда добавлена!🎉",
      PersonalRewardPlaceholder: "Укажите личную награду",
      PersonalRewardEmpty: "Награда не была добавлена до завершения",
      PersonalRewardReceiveConfirm: "Вы точно получили награду?",
      PersonalRewardReceive: "Получить",
      PersonalRewardReceived: "Получено",
      PersonalRewardAvailable: "Доступна награда",
      Results: "Результаты",
      ResultsTooltip: "Завершенные дела, которых не было в списке",
      ResultsDescription:
        "Отмечайте даже небольшие результаты и не забудьте оценить для статистики.",
      ResultsListEmpty: "Результаты пока не добавлены",
      ResultDeleteConfirm: "Удалить этот результат безвозвратно?",
      Nickname: "Никнейм",
      Rank: "Ранг",
      "TMR helper info":
        "TMR — things making ratio, число которое абстрактно показывает твой индекс активности в текущем сезоне.",
      "Finished goals": "Достигнутые цели",
      HomePageTitle: "Достигай целей быстрее и проще!",
      "Your email": "Ваш email",
      "Set email": "Добавить email",
      "Verification message":
        "Проверьте почту! Вам отправлено письмо с проверочным кодом.",
      "Verification code": "Проверочный код",
      "Why set email":
        "Добавление email предотвращает потерю всех данных вашей учетной записи в случае случайного выхода из неё на устройстве.",
      "Log out": "Выйти",
      Login: "Войти",
      AuthPageTitle: "Вход",
      "Email address": "Email адрес",
      "Login code": "Код для входа",
      "Login with Email": "Войти через Email",
      Send: "Отправить",
      "Login message":
        "Проверьте почту! Вам отправлено письмо с волшебной ссылкой и кодом для входа.",
      "Rules and privacy": "Правила и конфиденциальность",
      "Terms of Service": "Правила использования",
      "Privacy Policy": "Политика конфиденциальности",
      "Landing heading": `Планировщик дел и\u202fцелей`,
      "Landing title": "Достигай целей быстрее и проще!",
      "Landing subtitle":
        "Поддержание мотивации, ежедневник, достижения, статистика, тайм-трекер, фокусирование",
      "Landing Reason 1": "Обучающее задание для быстрого старта",
      "Landing Reason 2": "Синхронизация между устройствами",
      "Landing Reason 3": "Улучшаемые достижения",
      "Landing Reason 4": "Награды от себя за выполнение дел и целей",
      "Landing Reason 5": "Эффективные списки дел",
      "Landing Reason 6": "Персональный календарь активности",
      "Landing Reason 7": "Вложенные дела без ограничений по уровню",
      "Landing Reason 8": "Графики статистики продуктивности",
      "Landing Reason 9": "Повторяющиеся дела с настройкой расписания",
      "Log in": "Войти",
      "Demo goal1 name": "Помочь 10 людям достичь их жизненной цели",
      "Demo goal2 name": "Создать собственный бизнес",
      "Loading...": "Загрузка...",
      "Goal not found": "Цель не найдена",
      "Add goal": "Добавить цель",
      "Add todo": "Добавить дело",
      Priority: "Приоритет",
      Low: "Низкий",
      Normal: "Обычный",
      High: "Высокий",
      "Not found helper info": "Попробуй вернуться назад и найти то что искал.",
      "Finish this goal": "Завершить эту цель",
      "Goal finish info": `Поздравляем! Обрати внимание, что цель нельзя активировать повторно,
      цель и все её дела будут недоступны для редактирования.`,
      "Finish goal": "Завершить цель",
      Yes: "Да",
      Add: "Добавить",
      Delete: "Удалить",
      Finish: "Завершить",
      Cancel: "Отмена",
      Save: "Сохранить",
      Saving: "Saving",
      "Delete this goal": "Удалить эту цель",
      "Delete goal info": `Удаление безвозвратно. Цель не может быть восстановлена. Все связанные дела
      и напоминания будут удалены.`,
      "DELETE GOAL": "УДАЛИТЬ ЦЕЛЬ",
      "Blank todo": "Безымянное дело",
      "Untitled goal": "Безымянная цель",
      "Untitled todo": "Безымянное дело",
      "Open todo page": "Открыть страницу",
      Finished: "Выполнено",
      Achieved: "Достигнута",
      "Finish todo info": `Поздравляем! Обрати внимание, что дело нельзя активировать повторно,
      дело будет отмеченно выполненым и недоступным для редактирования.`,
      "FINISH TODO": "ВЫПОЛНИТЬ ДЕЛО",
      "Todos list info": "Это список дел для этой цели.",
      "This goal does not have todos yet.": "Для этой цели пока нет дел.",
      "Goals limit info": `Попробуй сфокусироваться на 5 главных целях. Увеличив количество активных целей,
      ты можешь прийти к рассеянию усилий и вообще не достичь какой-либо цели.`,
      "Reset password": "Восстановить пароль",
      "Password reset link sent to your email!":
        "Ссылка для сброса пароля отправлена на ваш email!",
      "Provided incorrect email!": "Указан неверный email!",
      "Can not receive reward!": "Не удалось получить награду!",
      SelectIcon: "Выберите иконку",
      RemoveIcon: "Убрать иконку",
      NextReward: "Следующая награда",
      rank: "разряд",
      ach_goals_name: "Через тернии к звёздам",
      ach_goals_content:
        "Завершайте цели чтобы получить и улучшить это достижение.",
      ach_goals_item: "цель",
      ach_goals_items: "целей",
      ach_goals_action: "достигнуто",
      ach_todos_name: "Дело мастера боится",
      ach_todos_content:
        "Выполняйте дела чтобы получить и улучшить это достижение.",
      ach_todos_item: "дело",
      ach_todos_items: "дел",
      ach_todos_action: "выполнено",
      ach_results_name: "Рыбка из пруда",
      ach_results_content:
        "Добавляйте результаты чтобы получить и улучшить это достижение.",
      ach_results_item: "результат",
      ach_results_items: "результатов",
      ach_results_action: "добавлено",
      ach_rise_and_shine_name: "Проснись и пой",
      ach_rise_and_shine_content:
        "Завершите первое задание — обучение, чтобы получить это достижение.",
      ach_tracked_time_name: "Делу время, потехе час",
      ach_tracked_time_content:
        "Отмечайте время в тайм-трекере чтобы получить и улучшить это достижение.",
      ach_tracked_time_item: "час",
      ach_tracked_time_items: "часов",
      ach_tracked_time_action: "отмечено",
      ach_premium_name: "Не в деньгах счастье",
      ach_premium_content:
        "Оплатите подписку ForgeSelf Премиум чтобы получить это достижение.",
      ach_feel_at_home_name: "Чувствуй себя как дома",
      ach_feel_at_home_content:
        "Загрузите фото профиля чтобы получить это достижение.",
      ach_feedback_name: "Лучшее — враг хорошего",
      ach_feedback_content:
        "Отправьте обратную связь чтобы получить это достижение.",
      ach_focusing_name: "Сам себе хозяин",
      ach_focusing_content:
        "Добавляйте карточки фокусирования чтобы получить и улучшить это достижение.",
      ach_focusing_item: "карточка",
      ach_focusing_items: "карточек",
      ach_focusing_action: "добавлено",
      "Access denied": "Доступ запрещён",
      "You must be signed in to view this page":
        "Вы должны войти чтобы просматривать эту страницу",
      "Page not found": "Страница не найдена",
      "Page not found details":
        "Попробуйте вернуться на главную и поискать еще раз.",
      CommonError: "Что-то пошло не так 😥 Попробуйте еще раз",
      OfflineMessage: "Нет подключения к интернету.",
      AccountSettings: "Настройки аккаунта",
      AccountDeleteInfo:
        "Вы можете полностью удалить свой аккаунт со всеми его данными. Удаленный аккаунт не подлежит восстановлению, данные удаляются безвозвратно.",
      AccountDelete: "Удалить аккаунт",
      AccountDeleteConfirm:
        "Вы уверены что хотите удалить свой аккаунт? Для удаления введите слово DELETE и нажмите на кнопку Удалить аккаунт.",
      AccountDeleteConfirmFinal: "Подумайте еще раз, удаление безвозвратно.",
      AccountDeleted: "Ваш аккаунт был удален по вашему запросу.",
      Support: "Поддержка",
      Emoji: "Эмоджи",
      AuthTitle: "Проснись и пой!",
      AuthSubtitle: "Введи email чтобы начать\nпуть к своим целям",
      TryingAuthSubtitle: "Пройди капчу чтобы начать\nпуть к своим целям",
      When: "Когда",
      Today: "Сегодня",
      Tomorrow: "Завтра",
      "Next week": "На следующей неделе",
      "Pick date": "Выбрать дату",
      Pending: "Отложено",
      "Chill, no todos for this list": "Расслабься, в этом списке дел нет",
      "No pending todos": "Нет отложенных дел",
      Clear: "Очистить",
      Repeat: "Повтор",
      Daily: "Ежедневно",
      "Working days": "Рабочие дни",
      Weekly: "Еженедельно",
      Monthly: "Ежемесячно",
      Yearly: "Ежегодно",
      Customize: "Настроить",
      MO: "ПН",
      TU: "ВТ",
      WE: "СР",
      TH: "ЧТ",
      FR: "ПТ",
      SA: "СБ",
      SU: "ВС",
      ImageDeleteConfirm: "Удалить это изображение?",
      NicknameLengthError: "Длина никнейма должна быть от 2 до 32 символов",
      "You will get": "Вы получите",
      experience: "Опыт",
      experiencePlural: "опыта",
      DeepLinksTitle: "Проверьте настройки приложения",
      DeepLinksButton: "Ссылки приложения",
      DeepLinksInfo: `Для того чтобы ссылки forgeself.com открывались в Android приложении,
      необходимо включить настройку "Открывать поддерживаемые ссылки" с опцией "Открывать", нажать "Добавить ссылку" > "forgeself.com".
      Это позволит авторизоваться в один клик по ссылке из письма.`,
      DeepLinksOpenAppSettings: "Открыть настройки приложения?",
      Week: "Неделя",
      Month: "Месяц",
      Period: "Период",
      Tags: "Теги",
      "Select or add tags": "Выберите или добавьте теги",
      Comment: "Комментарий",
      "Adding tracked time": "Добавляем отмеченное время",
      "Tracked time added": "Отмеченное время добавлено",
      TrackTimeChartEmptyTitle: "Нет данных за выбранный период",
      TrackTimeChartEmptyDescription:
        "Отмечайте время чтобы увидеть статистику",
      Confirm: "Подтверждение",
      TrackTimeDeleteConfirm: "Удалить это отмеченное время безвозвратно?",
      TrackedTimeEdit: "Редактировать отмеченное время",
      Blue: "Синий",
      Pink: "Розовый",
      Green: "Зеленый",
      Orange: "Оранжевый",
      Purple: "Фиолетовый",
      Yellow: "Жёлтый",
      Gray: "Серый",
      Red: "Красный",
      Brown: "Коричневый",
      Slate: "Шифер",
      "Time tracker": "Тайм-трекер",
      "Support ForgeSelf": "Поддержать ForgeSelf",
      TagDeleteConfirm: "Удалить этот тег безвозвратно?",
      Morning: "Утром",
      Afternoon: "Днём",
      Evening: "Вечером",
      "Move to": "Перенести в",
      Status: "Статус",
      "In progress": "Делаю",
      Base: "База",
      BaseTariffDescription: "Базовый тариф не требует подписки.",
      Premium: "Премиум",
      "Your subscription is active.": "Ваша подписка активна.",
      "Next payment date:": "Дата следующего платежа:",
      "Manage subscription": "Управлять подпиской",
      "Support project message":
        "Нравится ForgeSelf? Хочешь сделать его лучше? Забусти его прямо сейчас:",
      PremiumFeatureLimitsTitle: "Расширенные лимиты",
      PremiumFeatureLimitsSubitle: "До 50 активных целей, до 100 дел в списке.",
      PremiumFeatureFocusingTitle: "Фокусирование",
      PremiumFeatureFocusingSubitle:
        "Расставляйте приоритеты и фокусируйтесь на главном.",
      PremiumFeatureAutotimetrackingTitle: "Автоматический тайм-трекинг",
      PremiumFeatureAutotimetrackingSubitle:
        "Время потраченное на выполнение дел автоматически отмечается по тегам цели.",
      PremiumFeatureDetailedStatsTitle: "Подробная статистика",
      PremiumFeatureDetailedStatsSubitle:
        "Просмотр статистики за любой период.",
      PremiumFeaturePowerfulProgressTitle: "Мощный слайдер прогресса",
      PremiumFeaturePowerfulProgressSubitle:
        "Отмечайте прогресс дел с удобной диаграммой.",
      "Your tariff:": "Ваш тариф:",
      EARLYBIRD: "Ранняя пташка",
      "Next payment:": "Следующий платёж:",
      "Cancel subscription": "Отменить подписку",
      " and unlink the card": " и отвязать карту",
      LeaveSubscriptionConfirm:
        "Вы можете отказаться от автоматического продления. В этом случае, ForgeSelf больше не сможет выставлять Вам счета, а действие подписки завершится по окончании текущего периода.",
      Unsubscribe: "Отписаться",
      Focusing: "Фокусирование",
      FocusingPercentLabel: "Вы сфокусированы на ваших приоритетах на",
      FocusingPercentPositive: "Отличный результат! Продолжайте в том же духе!",
      FocusingPercentNegative: "Вы не в фокусе. Взгляните на ваши приоритеты.",
      FocusingChartEmptyTitle: "Недостаточно данных",
      FocusingChartEmptySubtitle:
        "Выберите приоритеты и отмечайте время чтобы построить график фокусирования",
      FocusingUnderfocusTitle: "Уделите больше времени этим категориям:",
      FocusingOverfocusTitle: "Уделите меньше времени этим категориям:",
      "My priorities": "Мои приоритеты",
      FocusPriority5: "Максимум внимания",
      FocusPriority4: "Важно",
      FocusPriority3: "Не забыть",
      FocusPriority2: "По ситуации",
      FocusPriority1: "Не важно",
      "Available with": "Доступно с",
      AutomaticGoalTagsTrackingMessage:
        "Автоматически отмечать время при завершении дел без категорий",
      progressHillLeft: "выясняем что к чему",
      progressHillRight: "реализуем задуманное",
      Pricing: "Тарифы",
      Bills: "Счета",
      Tariff: "Тариф",
      Date: "Дата",
      Sum: "Сумма",
      BILL_STATUS_SUCCESS: "Оплачен",
      BILL_STATUS_PENDING: "Ожидание",
      BILL_STATUS_CANCELED: "Отменён",
      SubscriptionEndsAt: "Подписка закончится",
      SubscriptionWebControls: "Управление подпиской",
      OpenInBrowser: "Открыть в браузере",
      NotAvailableInApp: "Управление подпиской пока недоступно в приложении",
      TimePickerStart: "Начало",
      TimePickerFinish: "Окончание",
      Progress: "Прогресс",
      "Due dates": "Сроки",
      "Goal set at": "Цель поставлена",
      "Due date": "Крайний срок",
      "Start date": "Дата начала",
      "Time left": "Осталось времени",
      "not specified": "не указан",
      "not specified / femine": "не указана",
      "not specified / neuter": "не указано",
      Overdue: "Просрочено",
      Schedule: "Расписание",
      "Total days": "Всего дней",
      "Best streak": "Лучшая серия",
      "Payment method: card *": "Способ оплаты: карта *",
      "web-version": "web-версия",
      CreateNewGoalList: "Создать новый список",
      "Adding goal list": "Добавляем список целей",
      "Goal list added": "Список целей добавлен",
      "Goal lists": "Списки целей",
      "All goals": "Все цели",
      "Untitled goal list": "Безымянный список целей",
      "Untitled todo list": "Безымянный список дел",
      GoalListsHelper:
        "Вы можете организовать цели в группы для удобной работы и\u202fфокусирования.",
      GoalListDeleteConfirm:
        "Удалить этот список целей безвозвратно? Цели внутри него не будут затронуты.",
      CreateNewTodoList: "Создать новый список",
      TodoListsManagement: "Управление списками",
      TodoListDeleteConfirm:
        "Удалить этот список дел безвозвратно? Дела внутри него будут удалены.",
      TodoListsModeToList: "Показать в виде списка",
      TodoListsModeToColumn: "Показать в виде столбцов",
      TodoListsHideFinished: "Скрыть завершенные",
      TodoListsShowFinished: "Показать завершенные",
      Search: "Поиск",
      SearchPlaceholder: "Поиск дел или целей",
      SearchResultEmpty: "Ничего не найдено",
      EditorAddDescription: "Добавить описание ...",
      EditorFinishEditing: "Завершить редактирование",
      EditorSetLink: "Добавить ссылку",
      FocusingCardContentPlaceholder: "Текст карточки ...",
      Archive: "Архив",
      Focus: "Фокус",
      FocusingSetArchived: "В архив",
      FocusingSetActive: "Активировать",
      FocusingCardDeleteConfirm: "Удалить эту карточку безвозвратно?",
      FocusingNoteFormMessageSuccess: "Карточка фокусирования добавлена!🎉",
      NotesFOCUSING_PRIORITY: "Приоритеты",
      NotesFOCUSING_ENERGY: "Энергия",
      NotesFOCUSING_ATTENTION: "Внимание",
      NotesFOCUSING_VALUES: "Ценности",
      NotesFOCUSING_PRIORITYQuestion:
        "Какие планы и задачи сейчас важнее всего?",
      NotesFOCUSING_ENERGYQuestion:
        "Какие занятия заряжают меня на творчество и продуктивность?",
      NotesFOCUSING_ATTENTIONQuestion:
        "Какие идеи, суждения и выводы стоит держать во внимании?",
      NotesFOCUSING_VALUESQuestion:
        "Какие принципы, убеждения и идеалы определяют мою жизненную позицию?",
      "Continue without login": "Продолжить без входа",
      FeedbackTitle: "Как вам эта страница?",
      FeedbackIsUseful: "Нравится / полезно",
      FeedbackIsUseless: "Не нравится / бесполезно",
      FeedbackIsConvenient: "Удобно / понятно",
      FeedbackIsUnconvenient: "Не удобно / не понятно",
      FeedbackPlaceholder: "Поделиться проблемой или замечанием",
      FeedbackFormCreate: "Отправить",
      FeedbackFormCreating: "Отправляем...",
      FeedbackFormMessageSuccess: "Обратная связь отправлена!🎉",
      FeedbackThanks:
        "Обратная связь отправлена. Благодарим за внимание к качеству продукта!",
    },
    settings: {
      UI_CUSTOM_PROGRESS: "Показывать слайдер прогресса для каждого дела",
      AppTheme: "Тема приложения",
      AppThemeLight: "Светлая",
      AppThemeDark: "Тёмная",
      AppThemeAuto: "Автоматически",
    },
    QUEST_RISE_AND_SHINE: {
      next: "Дальше",
      step1Title: "Добавь цель",
      step1Subtitle: "Укажи название и эмоджи",
      step1Action: "Создать цель",
      step2Title: "Создай список дел",
      step2Subtitle:
        "Назови шаги для достижения цели или задай регулярные задачи",
      step3Title: "Добавь награду",
      step3Subtitle: "Чем порадуете себя по завершении цели?",
      step4Title: "Отметь результат",
      step4Subtitle: "Что уже сделано для этой цели?",
      step5Title: "Отличное начало!",
      step5Subtitle:
        "Цель поставлена, путь проложен, есть мотивация и даже первый результат!",
      finish: "Завершить обучение",
    },
    docs: {
      Learn: "Знакомство",
      "Why ForgeSelf?": "Почему ForgeSelf?",
      "Use cases": "Способы применения",
      "How to": "Как пользоваться",
      Company: "Компания",
      "Goals achieving problem": "Проблема достижения целей",
      "Planning things problem": "Проблема планирования дел",
      "Flexibility solves": "Гибкость решает",
      "Positive motivation": "Позитивная мотивация",
      Personalization: "Персонализация",
      "Time saving": "Экономия времени",
      "Goal tracker": "Трекер целей",
      "Daily planner": "Ежедневник",
      "Focusing": "Фокусирование",
      "Time tracker": "Трекер времени",
    },
  },
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
