import classNames from "classnames";
import React, {
  CSSProperties,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Contenteditable.css";
import { useApp } from "@components/App/AppProvider";
import { App } from "antd";

const filterHtml = (e: ClipboardEvent) => {
  // Get user's pasted data
  let data =
    e.clipboardData?.getData("text/html") ||
    e.clipboardData?.getData("text/plain");

  // Filter out everything except simple text and allowable HTML elements
  let regex = /<(?!(\/\s*)?(a|b|i|em|s|strong|u)[>,\s])([^>])*>/g;
  data = (data ?? "").replace(regex, "");
  data = data
    .replace(/<\/?[^`]+?\/?>/gim, "\n") //replace all tags
    .replace(/\n[\s]*\n/gim, "\n"); //replace many empty lines to one

  // Insert the filtered content
  document.execCommand("insertHTML", false, data);

  // Prevent the standard paste behavior
  e.preventDefault();
};
function placeCaretAtEnd(el: HTMLElement) {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  } else if (typeof (document.body as any).createTextRange != "undefined") {
    var textRange = (document.body as any).createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}
function placeCaretInEmpty(el: HTMLElement) {
  el.focus();
  const selection = window.getSelection();
  if (selection) {
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
  }
}

interface ContenteditableProps {
  component?: string;
  onChange?: (e: { target: { value: string } }) => void;
  onFocus?: () => void;
  onFocusOut?: () => void;
  onBlur?: (value: string) => void;
  onEmpty?: () => void;
  html?: string;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  className?: string;
  maxlength?: number;
  onEnterInput?: (value: string) => void;
}
// : React.FC<ContenteditableProps>
// : React.Ref<HTMLDivElement>
const Contenteditable = React.forwardRef<
  { focusMe: () => void; blurMe: () => void },
  ContenteditableProps
>(
  (
    {
      component,
      onChange,
      onFocus,
      onFocusOut,
      onBlur,
      onEmpty,
      html,
      placeholder,
      style,
      disabled,
      className,
      maxlength,
      onEnterInput,
    },
    ref
  ) => {
    const { message } = App.useApp();
    const { handleStateChange } = useApp();
    const componentRef = useRef<HTMLElement>(null);
    const stopRef = useRef<Boolean>(false);
    const Component = component || ("div" as unknown as React.ElementType);
    const [confirmDelte, setConfirmDelete] = useState(false);
    const [innerValue, setInnerValue] = useState(html);
    const [inFocus, setInFocus] = useState(false);
    const isFocusingRef = useRef<boolean>(false);
    const makeChange = (event: any) => {
      // console.log(event.nativeEvent.inputType, componentRef.current.innerText);
      if (
        componentRef != null &&
        typeof componentRef !== "function" &&
        componentRef.current &&
        onChange
      ) {
        let newValue = "";
        if (
          maxlength &&
          componentRef != null &&
          typeof componentRef !== "function" &&
          componentRef.current &&
          componentRef.current.innerText.length > maxlength
        ) {
          componentRef.current.innerText =
            componentRef.current.innerText.substring(0, maxlength);
          placeCaretAtEnd(componentRef.current);
        }
        if (typeof componentRef !== "function" && componentRef.current) {
          newValue = onEnterInput
            ? componentRef.current.innerText.replace(/\r?\n|\r/g, "")
            : componentRef.current.innerText;
        }
        setInnerValue(newValue);
        if (newValue === "") {
          if (confirmDelte) {
            if (onEmpty) onEmpty();
          } else {
            setConfirmDelete(true);
          }
        } else {
          setConfirmDelete(false);
          onChange({
            target: {
              value: newValue,
            },
          });
        }
        if (stopRef.current && onEnterInput) onEnterInput(newValue);
      }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onEnterInput && event.key === "Enter") {
        event.preventDefault();
        stopRef.current = true;
        makeChange(event);
      } else {
        stopRef.current = false;
      }
    };
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      setInFocus(true);
      makeChange(event);
    };
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      handleStateChange({ isInputInFocus: false });
      if (
        onBlur &&
        componentRef != null &&
        typeof componentRef !== "function" &&
        componentRef.current
      )
        onBlur(componentRef.current.innerText);
      setInFocus(false);
      makeChange(event);
    };
    useEffect(() => {
      // console.log("reinit content");
      const node =
        componentRef != null && typeof componentRef !== "function"
          ? componentRef.current
          : null;
      if (node) {
        if (html) {
          node.innerText = html ?? "";
        } else {
          node.innerHTML = "<br>";
        }
        node.addEventListener("paste", filterHtml);
        setInnerValue(html);
        // placeCaretAtEnd(node);
      }
      return () => {
        if (node) node.removeEventListener("paste", filterHtml);
      };
    }, [Component, html]);

    const showPlaceholder = useMemo(
      () =>
        placeholder &&
        (!innerValue || `${innerValue}`.trim() === "") &&
        !inFocus,
      [placeholder, innerValue, inFocus]
    );

    const handleFocus = () => {
      if (onFocus) onFocus();
      setInFocus(true);
      setTimeout(function () {
        if (
          componentRef != null &&
          typeof componentRef !== "function" &&
          componentRef.current
        ) {
          placeCaretAtEnd(componentRef.current);
        }
      }, 100);
    };
    const handleFocused = () => {
      handleStateChange({ isInputInFocus: true });
      if (!innerValue?.trim() && componentRef.current) {
        placeCaretInEmpty(componentRef.current);
      }
      if (onFocus) onFocus();
      setInFocus(true);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          focusMe: () => {
            if (isFocusingRef.current) return;
            isFocusingRef.current = true;
            setInFocus(true);
            setTimeout(function () {
              if (componentRef.current) {
                placeCaretInEmpty(componentRef.current);
              }
              isFocusingRef.current = false;
            }, 100);
          },
          blurMe: () => {
            if (componentRef.current) {
              componentRef.current.blur();
            }
          },
        };
      },
      []
    );

    useEffect(() => {
      const contEdEl = componentRef.current;
      const handleFocusOut = (e: FocusEvent) => {
        if (onFocusOut) onFocusOut();
      };
      if (contEdEl) {
        contEdEl.addEventListener("focusout", handleFocusOut);
      }
      return () => {
        if (contEdEl) {
          contEdEl.removeEventListener("focusout", handleFocusOut);
        }
      };
    }, [onFocusOut]);

    return (
      <>
        {/* showPlaceholder ? (
          <Component
            key="placeholder"
            onClick={handleFocus}
            style={{ opacity: 0.5, ...(style ?? {}) }}
            className={classNames("Contenteditable", { disabled }, className)}
          >
            {placeholder}
          </Component>
        ) : null*/}
        <Component
          key="content"
          tabIndex={0}
          ref={componentRef}
          style={{
            // display: showPlaceholder ? "none" : "block",
            "--placeholder-text-var": showPlaceholder
              ? `\"${placeholder}\"`
              : "",
            opacity: showPlaceholder ? 0.5 : 1,
            ...(style ?? {}),
          }}
          onInput={disabled ? undefined : handleChange}
          onBlur={disabled ? undefined : handleBlur}
          onFocus={handleFocused}
          contentEditable={!disabled}
          className={classNames("Contenteditable", { disabled }, className)}
          onKeyDown={disabled ? undefined : handleKeyDown}
        ></Component>
      </>
    );
  }
);

export default React.memo(Contenteditable);
