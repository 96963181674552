import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  // FloatingMenu,
  BubbleMenu,
  EditorEvents,
  isTextSelection,
  EditorContent,
  useEditor,
  Editor,
} from "@tiptap/react";
import { BubbleMenuPluginProps } from "@tiptap/extension-bubble-menu";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import { marked } from "marked";
import { Markdown } from "tiptap-markdown";
import DOMPurify from "dompurify";
import styles from "./TextEditor.module.scss";
import BoldIcon from "@components/UI/Icons/Lucide/BoldIcon";
import ItalicIcon from "@components/UI/Icons/Lucide/ItalicIcon";
import UnderlineIcon from "@components/UI/Icons/Lucide/UnderlineIcon";
import HighlighterIcon from "@components/UI/Icons/Lucide/HighlighterIcon";
import { useTranslation } from "react-i18next";
import LucideLinkIcon from "@components/UI/Icons/Lucide/LucideLinkIcon";
import PencilIcon from "@components/UI/Icons/Lucide/PencilIcon";
import UnlinkIcon from "@components/UI/Icons/Lucide/UnlinkIcon";
import Contenteditable from "@components/Contenteditable/Contenteditable";
import Button from "@components/UI/Button/Button";
import { useApp } from "@components/App/AppProvider";
// define your extension array
const extensions = [
  StarterKit.configure({
    code: false,
    codeBlock: false,
    blockquote: false,
    bulletList: false,
    hardBreak: false,
    heading: false,
    listItem: false,
    orderedList: false,
    strike: false,
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
    protocols: ["http", "https"],
    isAllowedUri: (url, ctx) => {
      try {
        if (!(url.includes("http") || url.includes("https"))) {
          return false;
        }
        // construct URL
        const parsedUrl = url.includes(":")
          ? new URL(url)
          : new URL(`${ctx.defaultProtocol}://${url}`);

        // use default validation
        if (!ctx.defaultValidate(parsedUrl.href)) {
          return false;
        }

        // disallowed protocols
        const disallowedProtocols = ["ftp", "file", "mailto"];
        const protocol = parsedUrl.protocol.replace(":", "");

        if (disallowedProtocols.includes(protocol)) {
          return false;
        }

        // only allow protocols specified in ctx.protocols
        const allowedProtocols = ctx.protocols.map((p) =>
          typeof p === "string" ? p : p.scheme
        );

        if (!allowedProtocols.includes(protocol)) {
          return false;
        }

        // disallowed domains
        const disallowedDomains = ["play.google.com"];
        const domain = parsedUrl.hostname;

        if (disallowedDomains.includes(domain)) {
          return false;
        }

        // all checks have passed
        return true;
      } catch {
        return false;
      }
    },
    shouldAutoLink: (url) => {
      try {
        // construct URL
        const parsedUrl = url.includes(":")
          ? new URL(url)
          : new URL(`https://${url}`);

        // only auto-link if the domain is not in the disallowed list
        const disallowedDomains = ["play.google.com"];
        const domain = parsedUrl.hostname;

        return !disallowedDomains.includes(domain);
      } catch {
        return false;
      }
    },
  }),
  Markdown,
  Highlight,
  Underline,
];

interface TextEditorProps {
  editable?: boolean;
  placeholder?: string;
  initialValue: string;
  onChange: (value: string) => void;
}
const parseContent = (input: string) => {
  let htmlResult;
  try {
    htmlResult = DOMPurify.sanitize(marked.parse(input, { async: false }));
  } catch (e) {}
  return htmlResult;
};
const TextEditor = forwardRef<{ resetContent: () => void }, TextEditorProps>(
  ({ editable, placeholder, initialValue, onChange }, ref) => {
    const { handleStateChange } = useApp();
    const { t, i18n } = useTranslation("common");
    const html = useMemo(() => {
      return parseContent(initialValue);
    }, [initialValue]);
    const [isEditable, setIsEditable] = useState(false);

    const getPlaceholder = useCallback(() => {
      return t(placeholder || "EditorAddDescription");
    }, [t, placeholder]);

    const extensionsLocale = useMemo(
      () => [
        ...extensions,
        Placeholder.configure({
          placeholder: getPlaceholder,
          showOnlyWhenEditable: false,
          // Use different placeholders depending on the node type:
          // placeholder: ({ node }) => {
          //   if (node.type.name === 'heading') {
          //     return 'What’s the title?'
          //   }

          //   return 'Can you add some further context?'
          // },
        }),
      ],
      [getPlaceholder]
    );

    const handleUpdate = useCallback(
      ({ editor }: EditorEvents["update"]) => {
        const value = editor.storage.markdown.getMarkdown();
        onChange(value || null);
      },
      [onChange]
    );

    const editor = useEditor({
      extensions: extensionsLocale,
      content: html,
      onUpdate: handleUpdate,
      onFocus: () => {
        handleStateChange({ isInputInFocus: true });
      },
      onBlur: () => {
        handleStateChange({ isInputInFocus: false });
      },
      editable: editable !== undefined ? editable : isEditable,
    });
    useImperativeHandle(
      ref,
      () => {
        return {
          resetContent: (contentForReset?: string) => {
            if (html && editor)
              editor.commands.setContent(
                contentForReset ? parseContent(contentForReset) || html : html
              );
          },
        };
      },
      []
    );

    useEffect(() => {
      if (editor) {
        editor.setEditable(editable !== undefined ? editable : isEditable);
        if (editable !== undefined ? editable : isEditable) {
          editor.commands.focus("end");
        }
      }
    }, [editable, isEditable, editor]);

    return (
      <div className={styles.editorRoot}>
        {editable === undefined && !isEditable ? (
          <div style={{ position: "absolute", top: 0, right: 0, bottom: 0 }}>
            <Button
              className={styles.editToggle}
              v2
              variant="outlined"
              icon={<PencilIcon width={20} height={20} />}
              onClick={() => {
                setIsEditable(true);
              }}
              type="button"
            />
          </div>
        ) : null}
        <EditorContent editor={editor} />
        <BubbleMen editor={editor} />
        <LinkMenu editor={editor} />
        {editable === undefined && isEditable ? (
          <Button
            v2
            variant="outlined"
            onClick={() => {
              setIsEditable(false);
            }}
            type="button"
          >
            {t("EditorFinishEditing")}
          </Button>
        ) : null}
        {/* <FloatingMenu editor={null}>todo</FloatingMenu> */}
      </div>
    );
  }
);

const LinkMenu = ({ editor }: { editor: Editor | null }) => {
  // const { editor } = useCurrentEditor();
  const [editing, setEditing] = useState(false);
  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      tippyOptions={{
        // placement: open ? "bottom-start" : "bottom",
        onHidden: () => {
          setEditing(false);
        },
      }}
      editor={editor}
      className={styles.bubbleMenu}
      shouldShow={(props) => {
        setEditing(false);
        return props.editor.isActive("link") && props.editor.isEditable;
      }}
    >
      {editing ? (
        <LinkSetter
          isEditor
          editor={editor}
          onSet={() => {
            setEditing(false);
          }}
        />
      ) : (
        <>
          <a
            href={editor.getAttributes("link").href}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            {editor.getAttributes("link").href}
          </a>
          <button
            onClick={() => {
              setEditing(true);
            }}
            className={`${styles.bubbleButton} ${styles["bubbleButton--icon"]}`}
            type="button"
          >
            <PencilIcon width={20} height={20} />
          </button>
          <button
            onClick={() => editor.chain().focus().unsetLink().run()}
            className={`${styles.bubbleButton} ${styles["bubbleButton--icon"]}`}
            type="button"
          >
            <UnlinkIcon width={20} height={20} />
          </button>
        </>
      )}
    </BubbleMenu>
  );
};

const LinkSetter = ({
  isEditor,
  onSet,
  editor,
}: {
  isEditor?: boolean;
  onSet?: () => void;
  editor: Editor | null;
}) => {
  const { t } = useTranslation("common");
  const href = editor?.getAttributes("link").href || "";
  const [text, setText] = useState(href);
  const [value, setValue] = useState(href);
  const handleTextBlur = (text: string) => setText(text);
  const handleChange = useCallback((event: { target: { value: string } }) => {
    const val = event.target.value;
    setValue(val);
  }, []);
  const handleBlur = useCallback((newValue: string) => {
    handleTextBlur(newValue);
    setValue(newValue);
  }, []);
  useEffect(() => {
    setValue(editor?.getAttributes("link").href || "");
  }, [editor?.state.selection]);
  if (!editor) {
    return null;
  }
  return (
    <div className={styles.bubbleEditor}>
      <Contenteditable
        component="div"
        onChange={handleChange}
        onBlur={handleBlur}
        html={text}
        className={styles.bubbleInput}
      />
      <button
        onClick={() => {
          if (isEditor) {
            editor.commands.extendMarkRange("link");
            editor.commands.updateAttributes("link", { href: value });
          } else {
            editor
              .chain()
              .focus()
              .setLink({
                href: value,
                rel: "noopener noreferrer nofollow",
                target: "_blank",
              })
              .run();
          }
          if (onSet) onSet();
        }}
        className={styles.bubbleButton}
        type="button"
      >
        {t("EditorSetLink")}
      </button>
    </div>
  );
};

const shouldShow: Exclude<BubbleMenuPluginProps["shouldShow"], null> = ({
  view,
  state,
  from,
  to,
  element,
  editor,
}) => {
  const { doc, selection } = state;
  const { empty } = selection;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock =
    !doc.textBetween(from, to).length && isTextSelection(state.selection);

  // When clicking on a element inside the bubble menu the editor "blur" event
  // is called and the bubble menu item is focussed. In this case we should
  // consider the menu as part of the editor and keep showing the menu
  const isChildOfMenu = element.contains(document.activeElement);

  const hasEditorFocus = view.hasFocus() || isChildOfMenu;

  if (
    !hasEditorFocus ||
    empty ||
    isEmptyTextBlock ||
    !editor.isEditable ||
    editor.isActive("link")
  ) {
    return false;
  }

  return true;
};

const BubbleMen = ({ editor }: { editor: Editor | null }) => {
  const [linking, setLinking] = useState(false);
  if (!editor) {
    return null;
  }
  return (
    <BubbleMenu
      editor={editor}
      className={styles.bubbleMenu}
      shouldShow={shouldShow}
      tippyOptions={{
        // placement: open ? "bottom-start" : "bottom",
        onHidden: () => {
          setLinking(false);
        },
      }}
    >
      {linking ? (
        <LinkSetter
          editor={editor}
          onSet={() => {
            setLinking(false);
          }}
        />
      ) : (
        <>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={`${styles.bubbleButton} ${
              styles["bubbleButton--icon"]
            } ${editor.isActive("bold") ? styles["bubbleButton--active"] : ""}`}
            type="button"
          >
            {<BoldIcon width={20} height={20} />}
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={`${styles.bubbleButton} ${
              styles["bubbleButton--icon"]
            } ${
              editor.isActive("italic") ? styles["bubbleButton--active"] : ""
            }`}
            type="button"
          >
            <ItalicIcon width={20} height={20} />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`${styles.bubbleButton} ${
              styles["bubbleButton--icon"]
            } ${
              editor.isActive("underline") ? styles["bubbleButton--active"] : ""
            }`}
            type="button"
          >
            <UnderlineIcon width={20} height={20} />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className={`${styles.bubbleButton} ${
              styles["bubbleButton--icon"]
            } ${
              editor.isActive("highlight") ? styles["bubbleButton--active"] : ""
            }`}
            type="button"
          >
            <HighlighterIcon width={20} height={20} />
          </button>
          <button
            onClick={() => {
              setLinking(true);
            }}
            className={`${styles.bubbleButton} ${styles["bubbleButton--icon"]}`}
            type="button"
          >
            <LucideLinkIcon width={20} height={20} />
          </button>
        </>
      )}
    </BubbleMenu>
  );
};

export default React.memo(TextEditor);
