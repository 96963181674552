import React, {
  ChangeEvent,
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "antd";
import { gql, useQuery } from "@apollo/client";
import { Goal, Todo } from "@prisma/client";
import Spacer from "@components/Spacer/Spacer";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import styles from "./Search.module.scss";
import { Link } from "react-router-dom";
import Label from "@components/UI/Label";
import { useTranslation } from "react-i18next";
import CloudArrowDownIcon from "@components/UI/Icons/CloudArrowDownIcon";
import { useBoolean, useResponsive, useThrottle } from "ahooks";
import MagnifyingGlassIcon from "@components/UI/Icons/MagnifyingGlassIcon";
import Button from "@components/UI/Button/Button";
import { useApp } from "@components/App/AppProvider";

interface SearchProps {
  open: boolean;
  onClose: () => void;
}
const SearchParentsQuery = gql`
  query GlobalSearchResults(
    $query: String
    $sortBy: String
    $sortDirection: String
    $take: Int
  ) {
    todos(
      query: $query
      sortBy: $sortBy
      sortDirection: $sortDirection
      take: $take
    ) {
      id
      icon
      name
    }
    goals(
      query: $query
      sortBy: $sortBy
      sortDirection: $sortDirection
      take: $take
    ) {
      id
      icon
      name
    }
  }
`;

const Search: FC<SearchProps> = ({ open, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("common");
  const { handleStateChange } = useApp();
  const [inputValue, setInputValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const throttledValue = useThrottle(inputValue, { wait: 1500 });
  const { data, loading, error, refetch } = useQuery<{
    todos: Todo[];
    goals: Goal[];
  }>(SearchParentsQuery, {
    variables: { take: 5, sortBy: "updatedAt", sortDirection: "desc" },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setIsLoading(false);
    },
  });
  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refetch({
        ...(String(throttledValue).trim().length === 0
          ? { take: 5, query: null }
          : { query: throttledValue }),
        sortBy: "updatedAt",
        sortDirection: "desc",
        seed: new Date().valueOf(),
      });
      setIsLoading(false);
    })();
  }, [throttledValue]);

  const handleOpenChange = (open: boolean) => {
    if (open && inputRef.current) {
      (async () => {
        setIsLoading(true);
        await refetch({
          ...(String(throttledValue).trim().length === 0
            ? { take: 5, query: null }
            : { query: throttledValue }),
          sortBy: "updatedAt",
          sortDirection: "desc",
          seed: new Date().valueOf(),
        });
        setIsLoading(false);
      })();
      inputRef.current.focus();
    }
  };

  const resultGroups = useMemo(() => {
    return data
      ? [
          {
            key: "todo",
            list: data.todos,
            label: t("GoalTodos"),
          },
          {
            key: "goal",
            list: data.goals,
            label: t("Goals"),
          },
        ]
      : [];
  }, [t, data]);

  const pageResults = useMemo(() => {
    return inputValue && inputValue.trim().length > 0
      ? [{ label: t("Focusing"), link: "/focusing" }].filter((item) =>
          item.label.toLocaleLowerCase().match(inputValue.toLocaleLowerCase())
        )
      : [];
  }, [t, inputValue]);

  return (
    <>
      <Modal
        title={null}
        style={{ top: 80 }}
        open={open}
        footer={null}
        onCancel={onClose}
        closable={false}
        className={styles.modal}
        afterOpenChange={handleOpenChange}
      >
        <div>
          <input
            ref={inputRef}
            className={styles.searchInput}
            onChange={handleSearch}
            placeholder={t("SearchPlaceholder")}
            onFocus={() => {
              handleStateChange({ isInputInFocus: true });
            }}
            onBlur={() => {
              handleStateChange({ isInputInFocus: false });
            }}
          />
          {isLoading ? (
            <CloudArrowDownIcon
              width={24}
              height={24}
              className="blinking"
              style={{
                verticalAlign: "middle",
                position: "absolute",
                right: 16,
                top: 14,
                pointerEvents: "none",
              }}
            />
          ) : null}
        </div>
        {resultGroups.map((group) =>
          group.list && group.list.length > 0 ? (
            <Fragment key={group.key}>
              <Spacer y={1} />
              <div style={{ padding: "0 16px" }}>
                <Label>{group.label}</Label>
              </div>
              <Spacer y={1} />
              {group.list.map((item) => (
                <Link
                  key={item.id}
                  to={`/${group.key}/${item.id}`}
                  className={styles.searchResult}
                  onClick={onClose}
                >
                  {item.icon ? (
                    <>
                      <Emoji
                        unified={
                          Array.from(item.icon as string, (s) =>
                            s.codePointAt(0)?.toString(16)
                          ).join("-") ?? "2b1c"
                        }
                        emojiStyle={EmojiStyle.APPLE}
                        size={24}
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {item.name || t(`Untitled ${group.key}`)}
                </Link>
              ))}
            </Fragment>
          ) : null
        )}
        {pageResults.map((item) => (
          <Link
            key={item.label}
            to={item.link}
            className={styles.searchResult}
            onClick={onClose}
          >
            {item.label}
          </Link>
        ))}
        {data?.goals.length === 0 &&
        data?.todos.length === 0 &&
        pageResults.length === 0 &&
        !isLoading ? (
          <div style={{ padding: 16 }}>{t("SearchResultEmpty")}</div>
        ) : null}
      </Modal>
    </>
  );
};

export const SearchBar = ({
  variant = "text",
}: {
  variant?: "text" | "icon";
}) => {
  const { t } = useTranslation("common");
  const responsive = useResponsive();
  const isMobile = useMemo(
    () => !responsive.md && !responsive.lg && !responsive.xl,
    [responsive]
  );
  const [
    searchOpen,
    { setTrue: handleSearchOpen, setFalse: handleSearchClose },
  ] = useBoolean(false);

  return isMobile ? (
    <>
      {variant === "text" ? (
        <div className={styles.searchBar} onClick={handleSearchOpen}>
          <MagnifyingGlassIcon />
          {t("Search")}
        </div>
      ) : (
        <Button
          v2
          variant="outlined"
          icon={<MagnifyingGlassIcon width={24} height={24} />}
          onClick={handleSearchOpen}
        />
      )}
      <Search open={searchOpen} onClose={handleSearchClose} />
    </>
  ) : null;
};

export default Search;
