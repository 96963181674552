import React, { useMemo } from "react";
import styles from "./landing.module.scss";
import Spacer from "@components/Spacer/Spacer";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import AuthBlock from "@components/AuthBlock/AuthBlock";
import classNames from "classnames";
import { useResponsive } from "ahooks";
import useMediaQuery from "@lib/hooks/useMediaQuery";
import FSLogo from "@components/FSLogo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useApp } from "@components/App/AppProvider";
// import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "50px",
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "30px",
      },
    },
  ],
};

const Landing = () => {
  const { t, i18n } = useTranslation("common");
  const { theme } = useApp();
  const responsive = useResponsive();
  const isDarkMedia = useMediaQuery("(prefers-color-scheme: dark)");
  const isDarkScheme = theme === "dark" || isDarkMedia;
  const imgLight = theme ? theme === "light" : !isDarkScheme;
  const screenshots = useMemo(() => {
    const isMobile = !responsive.md && !responsive.lg && !responsive.xl;
    const lang = i18n.language === "ru" ? "ru" : "en";
    const screen = isMobile ? "mobile(rustore)_" : "desktop_";
    const scheme = imgLight ? "_light" : "_dark";
    const pageFiles = [
      "todos/desktop_todos_ru_light.jpg",
      "focusing/desktop_focusing_tab_FOCUSING_PRIORITY_ru_light.jpg",
      "goal/desktop_goal_ru_light.jpg",
      "goals/desktop_goals_ru_light.jpg",
      "timetracker/desktop_timetracker_tab_day_ru_light.jpg",
      "achievements/desktop_achievements_ru_light.jpg",
      "profile/desktop_profile_ru_light.jpg",
    ].map((item) => item.replace('_ru_', `_${lang}_`).replace('desktop_', screen).replace('_light', scheme));
    const result = pageFiles.map(
      (pageFile) =>
        `/images/jpg/${lang}/${pageFile}`
    );
    return result;
  }, [i18n.language, responsive, imgLight]);

  return (
    <>
      <div className={classNames(styles.container)}>
        <div>
          <div className={classNames(styles.section, styles.section1)}>
            <FSLogo width={64} height={64} />
            <Spacer y={2} />
            <h1 className={styles.title}>
              ForgeSelf <span>{t("Landing heading")}</span>
            </h1>
            <Spacer y={3} />
            {Capacitor.getPlatform() === "web" ? (
              <>
                {i18n.language === "ru" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      margin: "0",
                    }}
                    key="apps_ru"
                  >
                    <a
                      href="https://trk.mail.ru/c/me10h4?bundle_id=com.forgeself.app"
                      target="_blank"
                      style={{ margin: 8 }}
                    >
                      <img
                        src="/images/png/RuStore.png"
                        height={48}
                        alt="Доступно в RuStore"
                        style={{ verticalAlign: "top " }}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/ru/app/apple-store/id1575879890?pt=123272071&ct=badge&mt=8"
                      target="_blank"
                      style={{ margin: 8 }}
                    >
                      <img
                        src="/images/svg/Download_on_the_App_Store_Badge_RU.svg"
                        height={48}
                        alt="Загрузите в AppStore"
                        style={{ verticalAlign: "top " }}
                      />
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      margin: "0",
                    }}
                    key="apps_en"
                  >
                    <a
                      href="https://url.cloud.huawei.com/oTlegvU3QI?shareTo=qrcode"
                      target="_blank"
                      style={{ margin: "8px 0" }}
                    >
                      <img
                        src="/images/png/AppGallery-en-dark.png"
                        height={42}
                        alt="Explore it on AppGallery"
                        style={{ verticalAlign: "top " }}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/app/apple-store/id1575879890?pt=123272071&ct=badge&mt=8"
                      target="_blank"
                      style={{ margin: 8 }}
                    >
                      <img
                        src="/images/svg/Download_on_the_App_Store_Badge_EN.svg"
                        height={42}
                        alt="Download on the AppStore"
                        style={{ verticalAlign: "top " }}
                      />
                    </a>
                  </div>
                )}
              </>
            ) : null}
            <Spacer y={1} />
            <AuthBlock />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <Slider {...settings}>
              {screenshots.map((screenshot) => (
                <div key={screenshot} style={{ textAlign: "center" }}>
                  <div className={styles.slide}>
                    <img
                      className={styles.img}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "80vh",
                        margin: "0 auto",
                      }}
                      src={screenshot}
                      alt="app screenshot"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
