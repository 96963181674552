export const SETTING_UI_CUSTOM_PROGRESS = "SETTING_UI_CUSTOM_PROGRESS";
export const SETTING_UI_DASHBOARD_TAB = "SETTING_UI_DASHBOARD_TAB";
export const SETTING_UI_DASHBOARD_DISPLAY_FINISHED =
  "SETTING_UI_DASHBOARD_DISPLAY_FINISHED";
export const SETTING_UI_GOALS_TAB = "SETTING_UI_GOALS_TAB";
export const QUEST_RISE_AND_SHINE = "QUEST_RISE_AND_SHINE";
export const TAG_COLORS = [
  "#36a2eb",
  "#ff6394",
  "#4bc06f",
  "#ff9f40",
  "#9966ff",
  "#ffcd56",
  "#c9cbcf",
  "#ff5757",
  "#936b5d",
  "#607d8b",
];
export const PAGE_KEYS = {
  todos: "todos",
  todo: "todo",
  goals: "goals",
  goal: "goal",
  timetracker: "timetracker",
  focusing: "focusing",
  profile: "profile",
  achievements: "achievements",
};
