import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { App as AntdApp } from "antd";
import { Field, Form } from "react-final-form";
import Spacer from "@components/Spacer/Spacer";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import Button from "@components/UI/Button/Button";
import Accordion from "@components/UI/Accordion/Accordion";
import TextEditor from "@components/TextEditor/TextEditor";
import { Feedback } from "@prisma/client";
import { getLimits } from "@lib/limits";
import { useSession } from "@lib/auth";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import styles from "./CreateFeedback.module.scss";
dayjs.extend(utc);
dayjs.extend(timezone);

const FeedbacksQuery = gql`
  query FeedbacksQuery($pageKey: String, $zone: String) {
    feedbacks(pageKey: $pageKey, zone: $zone) {
      id
    }
  }
`;

const CreateFeedbackMutation = gql`
  mutation CreateFeedbackMutation(
    $pageKey: String!
    $isUseful: Boolean
    $isConvenient: Boolean
    $content: String
    $zone: String
  ) {
    createFeedback(
      pageKey: $pageKey
      isUseful: $isUseful
      isConvenient: $isConvenient
      content: $content
      zone: $zone
    ) {
      id
    }
  }
`;

interface FormValues {
  isUseful?: boolean;
  isConvenient?: boolean;
  content?: string;
}

const key = "goal_item";

const CreateFeedback: React.FC<{
  pageKey: string;
}> = ({ pageKey }) => {
  const { message } = AntdApp.useApp();
  const { t } = useTranslation("common");
  const { data: session, status } = useSession();
  const [sent, setSent] = useState(false);
  const { data, loading: listLoading } = useQuery<{
    feedbacks: Feedback[];
  }>(FeedbacksQuery, {
    fetchPolicy: "no-cache",
    variables: {
      zone: dayjs.tz.guess(),
      pageKey,
    },
  });
  const [createFeedback, { loading, error }] = useMutation(
    CreateFeedbackMutation,
    {
      fetchPolicy: "no-cache",
      // onCompleted: refetchList,
    }
  );

  const onSubmit = async (
    values: FormValues,
    form: FormApi<FormValues, FormValues>
  ) => {
    const { isUseful, isConvenient, content } = values;
    const variables = {
      pageKey,
      isUseful,
      isConvenient,
      content,
      zone: dayjs.tz.guess(),
    };
    // console.log(variables);
    // return;
    try {
      const createPromise = createFeedback({ variables });
      createPromise
        .then(() => {
          message.success({ content: t("FeedbackFormMessageSuccess"), key });
          setSent(true);
          // form.reset();
        })
        .catch((error) =>
          message.error({
            content: `${t("CommonError")} -  ${error}`,
            key,
          })
        );
    } catch (error) {
      console.error(error);
    }
  };

  return listLoading ||
    !data ||
    !data.feedbacks ||
    (!!session &&
      data.feedbacks.length >= getLimits(session?.user).feedbacks) ? null : (
    <>
      <Accordion title={t("FeedbackTitle")}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field name="isUseful">
                {({ input, meta }) => (
                  <div className={styles.row}>
                    <button
                      className={`${styles.btn} ${styles["btn--positive"]} ${
                        values.isUseful === true ? styles["btn--active"] : ""
                      } ${
                        values.isUseful === false ? styles["btn--inactive"] : ""
                      }`}
                      onClick={() =>
                        input.onChange(
                          values.isUseful === true ? undefined : true
                        )
                      }
                      type="button"
                      disabled={sent}
                    >
                      😊{t("FeedbackIsUseful")}
                    </button>
                    <button
                      className={`${styles.btn} ${styles["btn--negaitive"]} ${
                        values.isUseful === false ? styles["btn--active"] : ""
                      } ${
                        values.isUseful === true ? styles["btn--inactive"] : ""
                      }`}
                      onClick={() =>
                        input.onChange(
                          values.isUseful === false ? undefined : false
                        )
                      }
                      type="button"
                      disabled={sent}
                    >
                      ☹️{t("FeedbackIsUseless")}
                    </button>
                  </div>
                )}
              </Field>
              <Spacer y={1} />
              <Field name="isConvenient">
                {({ input, meta }) => (
                  <div className={styles.row}>
                    <button
                      className={`${styles.btn} ${styles["btn--positive"]} ${
                        values.isConvenient === true
                          ? styles["btn--active"]
                          : ""
                      } ${
                        values.isConvenient === false
                          ? styles["btn--inactive"]
                          : ""
                      }`}
                      onClick={() =>
                        input.onChange(
                          values.isConvenient === true ? undefined : true
                        )
                      }
                      type="button"
                      disabled={sent}
                    >
                      😎{t("FeedbackIsConvenient")}
                    </button>
                    <button
                      className={`${styles.btn} ${styles["btn--negaitive"]} ${
                        values.isConvenient === false
                          ? styles["btn--active"]
                          : ""
                      } ${
                        values.isConvenient === true
                          ? styles["btn--inactive"]
                          : ""
                      }`}
                      onClick={() =>
                        input.onChange(
                          values.isConvenient === false ? undefined : false
                        )
                      }
                      type="button"
                      disabled={sent}
                    >
                      😵‍💫{t("FeedbackIsUnconvenient")}
                    </button>
                  </div>
                )}
              </Field>
              <Field name="content">
                {({ input, meta }) => (
                  <TextEditor
                    initialValue={values.content || ""}
                    onChange={input.onChange}
                    placeholder="FeedbackPlaceholder"
                    editable={!sent}
                  />
                )}
              </Field>
              <Spacer y={2} />
              {sent ? (
                <div className="notice">{t("FeedbackThanks")}</div>
              ) : (
                <Button
                  loading={loading}
                  type="submit"
                  color="primary"
                  disabled={
                    !values.content &&
                    values.isConvenient === undefined &&
                    values.isUseful === undefined
                  }
                >
                  {loading
                    ? t("FeedbackFormCreating")
                    : t("FeedbackFormCreate")}
                </Button>
              )}
            </form>
          )}
        />
      </Accordion>
    </>
  );
};

export default CreateFeedback;
