import { forwardRef, useEffect, useMemo } from "react";
import { Layout as AntLayout } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";
import Spacer from "@components/Spacer/Spacer";
import Button from "@components/UI/Button/Button";
import { useCurrentPath } from "@components/App/App";
import { useSession } from "@lib/auth";
import { useResponsive } from "ahooks";
import Nav from "@components/Nav/Nav";
import { useApp } from "@components/App/AppProvider";
import styles1 from "../App/App.module.scss";
import { PAGE_KEYS, QUEST_RISE_AND_SHINE } from "../../constants";
import styles from "./Layout.module.scss";
import FSLogo from "@components/FSLogo";
import TelegramIcon from "@components/UI/Icons/TelegramIcon";
import CreateFeedback from "@components/Form/CreateFeedback/CreateFeedback";

const { Content } = AntLayout;

interface LayoutProps {
  children: React.ReactNode;
  noWrap?: boolean;
  fullWidth?: boolean;
}

const Layout = forwardRef<HTMLElement, LayoutProps>(
  ({ children, noWrap = false, fullWidth = false }, ref) => {
    const { t, i18n } = useTranslation("common");
    const { t: t3 } = useTranslation("docs");
    const path = useCurrentPath();
    const { data: session, status } = useSession();
    const { firstQuestFinished, handleOpenQuest } = useApp();
    const { t: t2 } = useTranslation(QUEST_RISE_AND_SHINE);
    const responsive = useResponsive();
    const isMobile = useMemo(
      () => !responsive.md && !responsive.lg && !responsive.xl,
      [responsive]
    );
    const pageKey = useMemo(() => {
      if (path === "/dashboard") return PAGE_KEYS.todos;
      if (path === "/goals") return PAGE_KEYS.goals;
      if (path?.includes("/todo/")) return PAGE_KEYS.todo;
      if (path?.includes("/goal/")) return PAGE_KEYS.goal;
      if (path === "/focusing") return PAGE_KEYS.focusing;
      if (path === "/timetracker") return PAGE_KEYS.timetracker;
      if (path === "/profile") return PAGE_KEYS.profile;
      if (path === "/achievements") return PAGE_KEYS.achievements;
      return undefined;
    }, [path]);
    const loadingAuth = status === "loading";

    /* useEffect(() => {
      document.querySelector('#scrollRoot')?.scrollTo(0,0)
    }, [path]) */

    return (
      <>
        <Nav
          finishQuestButton={
            !!session &&
            !loadingAuth &&
            !firstQuestFinished &&
            firstQuestFinished !== null ? (
              <button className={styles1.questBtn} onClick={handleOpenQuest}>
                <img
                  width="48"
                  src="/images/png/HatchingChick.png"
                  alt="HatchingChick"
                />
                <Spacer x={2} />
                {t2("finish")}
              </button>
            ) : null
          }
          setupEmailButton={
            !!session && !loadingAuth && session.user.emailVerified === null ? (
              <Link
                className={styles1.emailBtn}
                to="/profile#emailSettings"
                onClick={() => {
                  const el = document.getElementById(
                    window.location.hash.replace("#", "")
                  );
                  el?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                {t("Set email")}
              </Link>
            ) : null
          }
        />
        <AntLayout
          ref={ref}
          id="scrollRoot"
          style={{
            overflow: "auto",
            display: "block",
            background: "transparent",
          }}
        >
          <Content>
            {noWrap ? (
              children
            ) : (
              <div
                className="tooltipBoundary"
                style={{
                  padding: 16,
                  minHeight: 360,
                  margin: "0 auto",
                  maxWidth: fullWidth ? "100%" : 1100,
                  width: fullWidth ? "100%" : undefined,
                }}
              >
                <main style={{ minHeight: "calc(100vh - 16px)" }}>
                  {children}
                </main>
                {pageKey ? (
                  <div style={{ maxWidth: 640, margin: "0 auto" }}>
                    <Spacer y={2} />
                    <CreateFeedback pageKey={pageKey} />
                  </div>
                ) : null}
              </div>
            )}
          </Content>
          {(!session && status !== "loading") || path === "/profile" ? (
            <footer className={styles.footer}>
              <div className={styles.footerContent}>
                <div>
                  <Link to="/">
                    <FSLogo width={32} height={32} />
                  </Link>
                </div>

                <div>
                  <div className={styles.footerLabel}>{t3("Learn")}</div>
                  <Link
                    className={styles.footerLink}
                    to="/goals-achieving-problem"
                  >
                    {t3("Goals achieving problem")}
                  </Link>
                  <br />
                  <Link
                    className={styles.footerLink}
                    to="/planning-things-problem"
                  >
                    {t3("Planning things problem")}
                  </Link>
                  <br />
                  <Link className={styles.footerLink} to="/flexibility-solves">
                    {t3("Flexibility solves")}
                  </Link>
                </div>
                <div>
                  <div className={styles.footerLabel}>
                    {t3("Why ForgeSelf?")}
                  </div>
                  <Link className={styles.footerLink} to="/positive-motivation">
                    {t3("Positive motivation")}
                  </Link>
                  <br />
                  <Link className={styles.footerLink} to="/personalization">
                    {t3("Personalization")}
                  </Link>
                  <br />
                  <Link className={styles.footerLink} to="/time-saving">
                    {t3("Time saving")}
                  </Link>
                </div>
                <div>
                  <div className={styles.footerLabel}>{t3("Use cases")}</div>
                  <Link
                    className={styles.footerLink}
                    to="/use-cases/goal-tracker"
                  >
                    {t3("Goal tracker")}
                  </Link>
                  <br />
                  <Link
                    className={styles.footerLink}
                    to="/use-cases/daily-planner"
                  >
                    {t3("Daily planner")}
                  </Link>
                  <br />
                  <Link
                    className={styles.footerLink}
                    to="/use-cases/focusing"
                  >
                    {t3("Focusing")}
                  </Link>
                  <br />
                  <Link
                    className={styles.footerLink}
                    to="/use-cases/time-tracker"
                  >
                    {t3("Time tracker")}
                  </Link>
                </div>
                <div>
                  <div className={styles.footerLabel}>{t3("Company")}</div>
                  <Link className={styles.footerLink} to="/policy">
                    {t("Rules and privacy")}
                  </Link>
                  <br />
                  <Link className={styles.footerLink} to="/pricing">
                    {t("Pricing")}
                  </Link>
                  <br />
                  <Link className={styles.footerLink} to="/support">
                    {t("Support")}
                  </Link>
                </div>
              </div>
              <Spacer y={2} />
              {i18n.language === "ru" ? (
                <div style={{ textAlign: "center" }}>
                  <Spacer y={2} />
                  <a
                    href="https://t.me/forgeself"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-flex",
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <TelegramIcon width={24} height={24} />
                    <div style={{ marginTop: -2 }}>Подпишись в Телеграм</div>
                  </a>
                </div>
              ) : null}
              <Spacer y={2} />
              <div className={styles.copyright}>
                {i18n.language === "ru"
                  ? "© 2022-2025 ForgeSelf. Все права защищены."
                  : "Copyright © 2022-2025 ForgeSelf. All rights reserved."}
              </div>
              <Spacer y={4} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="small"
                  variant={i18n.language === "ru" ? "default" : "text"}
                  onClick={async () => {
                    if (process.env.NODE_ENV !== "production") {
                      await i18n.reloadResources();
                    }
                    await i18n.changeLanguage("ru");
                    await Preferences.set({
                      key: "settingsLanguage",
                      value: "ru",
                    });
                  }}
                >
                  RU
                </Button>
                <Spacer x={2} />
                <Button
                  size="small"
                  variant={i18n.language === "en" ? "default" : "text"}
                  onClick={async () => {
                    if (process.env.NODE_ENV !== "production") {
                      await i18n.reloadResources();
                    }
                    await i18n.changeLanguage("en");
                    await Preferences.set({
                      key: "settingsLanguage",
                      value: "en",
                    });
                  }}
                >
                  EN
                </Button>
              </div>
            </footer>
          ) : null}
        </AntLayout>
      </>
    );
  }
);

export default Layout;
