import React from "react"; // , { useEffect, useRef } 
import { useBoolean } from "ahooks";
// import { useSpring, animated, config } from "react-spring";
// import useMeasure from "@lib/hooks/useMeasure";
import Spacer from "@components/Spacer/Spacer";
import Button from "../Button/Button";
import ChevronDownIcon from "../Icons/ChevronDownIcon";

const Accordion = ({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) => {
  const [expanded, actions] = useBoolean(false);

  {
    /*
  const mountedRef = useRef<boolean>(false);
   const [measureRef, { height }] = useMeasure();

  const collapseStyles = useSpring({
    immediate: !mountedRef.current,
    config: config.default,
    from: {
      height: 0,
    },
    to: {
      height: expanded ? height : 0,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 100);
  }, []); */
  }
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontWeight: "bold" }}>{title}</div>
        <Spacer x={2} />
        <Button
          v2
          icon={
            <ChevronDownIcon
              width={20}
              height={20}
              style={{
                transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            />
          }
          size="small"
          onClick={() => actions.toggle()}
        />
      </div>
      <Spacer y={2} />
      {expanded ? children : null}
      {/* хуй знает почему скролит прямо в этот блок при открытии страницы <animated.div
        style={{
          overflow: "hidden",
          ...collapseStyles,
        }}
      >
        <div ref={measureRef}></div>
      </animated.div> */}
    </>
  );
};

export default Accordion;
